import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text } from '@/components/atoms';
import {
  BannerSlider,
  GalleryProduct,
  FilterSort,
  Sort,
  ProductList,
  CategoriesGroup
} from '@/components/organisms';
import { Breadcrumb, Title, Chips } from '@/components/molecules';
import Layout from '@/components/Layout';
import styles from './index.module.scss';
import useDeviceType from '@/hooks/useDeviceType';

const ProductsGalleryPage = ({
  title,
  pagination,
  description,
  landingDescription
}) => {
  const { isDesktop } = useDeviceType();
  const { filtersActive, haveCategories } = useSelector(
    (state) => state.products
  );
  const banner = useSelector((state) => state.banners.gallery);
  return (
    <Layout
      isGalleryPage
      title={title}
      description={description}
    >
      {banner?.id &&
        !haveCategories && ( // si no hay subcategorías se muestra un el banner principal
          <BannerSlider
            border
            banners={[banner]}
            priority
          />
        )}
      <div className={styles.containerGallery}>
        <div
          style={{
            paddingTop: '1rem'
          }}
        >
          <Title
            variant="h1"
            labelSize="l"
            labelCase="uppercase"
            label={title
              ?.replace(/\|\s*Página\s*\d+/i, '')
              .replace('Comprá ', '')}
          />
        </div>
        {haveCategories?.sub_categories && (
          <CategoriesGroup categories={haveCategories.sub_categories} />
        )}
        {isDesktop && <Breadcrumb style={{ margin: 0 }} />}
        {landingDescription && (
          <Text
            textStyle={{ gridColumn: '2/3', marginTop: '50px' }}
            textColor="white"
            weight="light"
            maxLineLength={3}
          >
            {landingDescription}
          </Text>
        )}
        <div className={styles.container}>
          <div>
            {isDesktop && (
              <div className={styles.title}>
                <Text textColor="white">Filtrar por</Text>
              </div>
            )}

            <FilterSort title={title?.replace(/\|\s*Página\s*\d+/i, '')} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.galleryHeader}>
              <Chips
                slugs={[filtersActive.keywords, ...filtersActive.slugs].filter(
                  (s) => s
                )}
                style={{
                  marginBottom: '10px',
                  width: isDesktop ? '68%' : '100%'
                }}
                gender={filtersActive.gender}
              />
              {isDesktop && <Sort />}
            </div>
            <GalleryProduct pagination={pagination}>
              <ProductList />
            </GalleryProduct>
          </div>
        </div>
      </div>
      {!isDesktop && <Breadcrumb />}
    </Layout>
  );
};
ProductsGalleryPage.propTypes = {
  pagination: PropTypes.object,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  landingDescription: PropTypes.string
};
ProductsGalleryPage.defaultProps = {
  pagination: {},
  description: '',
  landingDescription: ''
};
export default ProductsGalleryPage;
